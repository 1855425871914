<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
       <v-toolbar-title>Edit Recurrent Transaction Template</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" small text @click="goBack">
          <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
         <!-- editor -->
        <editor
          :initial="dataItem"
          :docCreate="docCreate"
          @data="save"
        ></editor>
        <!-- end -->
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },

  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    docCreate: false,
  }),
  watch: {
    "$route.params.id": {
      handler: "getItem",
      immediate: true,
    },
  },
  methods: {
    getItem(id) {
      const self = this;
      this.$store
        .dispatch("get", `/recurringtransactiontemplates/${id}`)
        .then((res) => {
          self.dataItem = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      console.log("EMIT",data);
      const url = `/recurringtransactiontemplates/${data.id}`;
      const self = this;
      this.$store
        .dispatch("put", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
              this.$store.commit("loader", false);
            this.$refs.snackbar.show(res.ResultDesc, "green");
            self.$router.push("/sales/recurrent-templates");
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>