<template>
  <v-form>
    <v-row>
      <v-col cols="3">
        <v-text-field label="Template" v-model="record.Code"></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field label="Description" v-model="record.Dscription"></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-autocomplete
          v-model="record.DocObjType"
          :items="docTypes"
          item-text="DocumentName"
          item-value="ObjectID"
          label="Type"
        ></v-autocomplete>
      </v-col>

      <v-col cols="3">
        <v-text-field
          :disabled="AllowSelectDoc"
          label="Doc No"
          :append-icon="'mdi-airballoon'"
          @click:append="openDraftsDialog"
          v-model="draftDoc.DocNum"
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-autocomplete
          @change="updateFrequency()"
          v-model="record.Frequency"
          :items="frequencies"
          item-text="name"
          item-value="value"
          label="Recurrence Period"
        ></v-autocomplete>
      </v-col>

      <v-col cols="3">
        <v-autocomplete
          :disabled="SelRemind"
          v-model="record.Remind"
          @change="getNextExecution()"
          :items="reminders"
          item-text="name"
          item-value="value"
          label="Recurrence Date"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time2"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="record.ExecTime"
              label="Execution Time"
              prepend-icon="mdi-clock-time-four-outline"
              @change="getNextExecution()"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu2"
            v-model="record.ExecTime"
            full-width
            @click:minute="$refs.menu.save(time2)"
          ></v-time-picker>
        </v-menu>
      </v-col>

      <v-col cols="3">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Start Date"
              v-on="on"
              dense
              v-model="record.StartDate"
              outlined
            ></v-text-field>
          </template>
          <v-date-picker v-model="record.StartDate"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              label="Valid Until"
              v-on="on"
              @change="getNextExecution()"
              outlined
              v-model="record.EndDate"
            ></v-text-field>
          </template>
          <v-date-picker v-model="record.EndDate"></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="3">
        <v-text-field
          label="Next Execution"
          v-model="record.ExecDay"
          filled
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field
          label="Total No. of Instances"
          v-model="record.Instance"
          filled
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field label="Bp" v-model="record.CardCode" filled></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field label="Bp Name" v-model="record.CardName" filled></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field label="Warehouse" v-model="record.WhsCode" filled></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field label="Doc Total" v-model="record.DocTotal" filled></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field label="Remarks" v-model="record.CardCode" filled></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-btn color="accent" @click="sendData">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>

    <!-- one docs modal -->
    <v-dialog v-model="draftsDialog" width="1200">
      <v-toolbar dark color="primary">
        <v-toolbar-title>List of Drafts</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="draftsDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="searchItem"
                    append-icon="mdi-magnify"
                    label="Search"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  dense
                  @item-selected="clickedItem"
                  :items-per-page="itemsPerPage"
                  v-model="selectedDocs"
                  :headers="draftsHeaders"
                  :items="drafts"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  :search="search"
                  :options.sync="options"
                  :server-items-length="totalItems"
                  class="elevation-1"
                  :server-search="true"
                >
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <snackbar ref="snackbar"></snackbar>
      </v-card>
    </v-dialog>
    <!-- end of docs modal -->
  </v-form>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    AllowSelectDoc: true,
    SelRemind: true,
    record: {},
    search: null,
    searchItem: null,
    loading: false,
    singleSelect: true,
    selectedDocs: [],
    draftsDialog: false,
    itemsPerPage: 20,
    StartDate: null,
    EndDate: null,
    countries: [],
    draftDoc: {},
    drafts: [],
    reminders: [],
    menu2: false,
    time2: "",
    docTypes: [],
    frequencies: [
      { name: "Daily", value: "D" },
      { name: "Monthly", value: "M" },
      { name: "Weekly", value: "W" },
      { name: "Quarterly", value: "Q" },
      { name: "Semi Annually", value: "S" },
      { name: "Annually", value: "A" },
      { name: "One Time", value: "O" },
    ],
    DailyReminders: [
      { name: "Daily", value: 1000 },
      { name: "Every 1", value: 1001 },
      { name: "Every 2", value: 1002 },
      { name: "Every 3", value: 1003 },
      { name: "Every 4", value: 1004 },
      { name: "Every 5", value: 1005 },
      { name: "Every 6", value: 1006 },
      { name: "Every 7", value: 1007 },
      { name: "Every 8", value: 1008 },
      { name: "Every 9", value: 1009 },
      { name: "Every 10", value: 1010 },
      { name: "Every 11", value: 1011 },
      { name: "Every 12", value: 1012 },
      { name: "Every 13", value: 1013 },
      { name: "Every 14", value: 1014 },
      { name: "Every 15", value: 1015 },
      { name: "Every 16", value: 1016 },
      { name: "Every 17", value: 1017 },
      { name: "Every 18", value: 1018 },
      { name: "Every 19", value: 1019 },
      { name: "Every 20", value: 1020 },
      { name: "Every 21", value: 1021 },
      { name: "Every 22", value: 1022 },
      { name: "Every 23", value: 1023 },
      { name: "Every 24", value: 1024 },
      { name: "Every 25", value: 1025 },
      { name: "Every 26", value: 1026 },
      { name: "Every 27", value: 1027 },
      { name: "Every 28", value: 1028 },
      { name: "Every 29", value: 1029 },
      { name: "Every 30", value: 1030 },
    ],

    MonthlyReminders: [
      { name: "On 1", value: 1001 },
      { name: "On 2", value: 1002 },
      { name: "On 3", value: 1003 },
      { name: "On 4", value: 1004 },
      { name: "On 5", value: 1005 },
      { name: "On 6", value: 1006 },
      { name: "On 7", value: 1007 },
      { name: "On 8", value: 1008 },
      { name: "On 9", value: 1009 },
      { name: "On 10", value: 1010 },
      { name: "On 11", value: 1011 },
      { name: "On 12", value: 1012 },
      { name: "On 13", value: 1013 },
      { name: "On 14", value: 1014 },
      { name: "On 15", value: 1015 },
      { name: "On 16", value: 1016 },
      { name: "On 17", value: 1017 },
      { name: "On 18", value: 1018 },
      { name: "On 19", value: 1019 },
      { name: "On 20", value: 1020 },
      { name: "On 21", value: 1021 },
      { name: "On 22", value: 1022 },
      { name: "On 23", value: 1023 },
      { name: "On 24", value: 1024 },
      { name: "On 25", value: 1025 },
      { name: "On 26", value: 1026 },
      { name: "On 27", value: 1027 },
      { name: "On 28", value: 1028 },
      { name: "On 29", value: 1029 },
      { name: "On 30", value: 1030 },
    ],

    WeeklyReminders: [
      { name: "Monday", value: 1001 },
      { name: "Tuesday", value: 1002 },
      { name: "Wednesday", value: 1003 },
      { name: "Thursday", value: 1004 },
      { name: "Friday", value: 1005 },
      { name: "Saturday", value: 1006 },
      { name: "Sunday", value: 1007 },
    ],
    draftsHeaders: [
      { text: "Doc No", value: "DocNum" },
      { text: "Cusomer Code", value: "CardCode" },
      { text: "Tenant Name", value: "CardName" },
      { text: "Comments", value: "Comments" },
      { text: "Doc Total", value: "DocTotal" },
    ],
    options: {
      rowsPerPageOptions: [10, 20, 30],
      itemsPerPageOptions: [10, 20, 30],
      itemsPerPage: 10,
      page: 1,
    },
    totalItems: 0,
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
    record: {
      handler: "showDocSelect",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    openDraftsDialog() {
      this.draftsDialog = true;
      this.getDrafts();
    },
    clickedItem($event) {
      this.draftDoc = $event.item;
      this.draftsDialog = false;
    },
    // setStartDate(date) {
    //   this.StartDate = date;
    // },
    // setEndDate(date) {
    //   this.EndDate = date;
    // },
    // setDate(date) {
    //   this.record.date = date;
    // },
    sendData() {
      this.record.DraftEntry = this.draftDoc.id;
      // this.record.StartDate = this.StartDate;
      // this.record.EndDate = this.EndDate;
      this.$emit("data", this.record);
    },
    showDocSelect() {
      if (this.record.DocObjType) {
        this.AllowSelectDoc = false;
        this.drafts = [];
      } else {
        this.AllowSelectDoc = true;
      }
      this.getNextExecution();
    },
    getDocs() {
      const self = this;
      this.$store
        .dispatch("get", `/doc_model`)
        .then((res) => {
          self.docTypes = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getDrafts() {
      const self = this;
      this.loading = true;

      let url = `/drafts/?ObjType=${this.record.DocObjType}`;
      if (this.searchItem && this.searchItem != undefined) {
        url += `&search=${this.searchItem}`;
      }
      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.drafts = res.ResponseData.data;
          self.options.page = res.ResponseData.current_page;
          self.options.itemsPerPage = res.ResponseData.per_page;
          self.totalItems = res.ResponseData.total;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    updateFrequency() {
      switch (this.record.Frequency) {
        case "D":
          this.reminders = this.DailyReminders;
          this.SelRemind = false;
          break;
        case "W":
          this.reminders = this.WeeklyReminders;
          this.SelRemind = false;
          break;
        case "M":
          this.reminders = this.MonthlyReminders;
          this.SelRemind = false;
          break;
        default:
          this.reminders = [{ name: "-1", value: "-1" }];
          this.record.Remind = "-1";
          this.SelRemind = true;
          break;
      }
    },
    getNextExecution() {
      if (
        this.record.Frequency &&
        this.record.StartDate &&
        this.record.EndDate &&
        this.record.Remind
      ) {
        const self = this;
        this.loading = true;
        let url = `/recurringtransaction/next-execution`;
        let data = this.record;
        this.$store
          .dispatch("post", { url, data })
          .then((res) => {
            console.log(res);
            self.record.ExecDay = res.ResponseData;
            self.loading = false;
          })
          .catch((err) => {
            console.log(err);
            // this.$refs.snackbar.show(err, "red");
          });
      } else {
        console.log("no data");
      }
    },
  },
  created() {
    this.getDocs();
  },
};
</script>
